<template>
<div>
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="container">
            <router-link class="navbar-brand" :to="{path: '/'}">{{ $root.siteName }}</router-link>
            <button class="navbar-toggler" type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <button class="navbar-toggler" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                    <i class="fe fe-x"></i>
                </button>
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <a :href="`${$root.siteProto}://api.${$root.siteDomain}/docs`" class="nav-link">API</a>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{path: '/features'}" class="nav-link">Features</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{path: '/pricing'}" class="nav-link">Pricing</router-link>
                    </li>
                </ul>
                <router-link :to="{path: '/dashboard'}" class="navbar-btn btn btn-sm btn-primary lift ms-auto" v-if="$root.context.id">
                    Console
                </router-link>
                <router-link :to="{path: '/registration'}"
                             class="navbar-btn btn btn-sm btn-primary lift ms-auto" v-else>
                    Register
                </router-link>
            </div>
        </div>
    </nav>
    <main>
        <router-view></router-view>
    </main>
    <div class="position-relative">
        <div class="shape shape-bottom shape-fluid-x svg-shim text-gray-200">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"/></svg>
        </div>
    </div>
    <footer class="footer py-8 py-md-11 bg-gray-200">
        <Footer />
    </footer>
</div>
</template>

<script>
import Footer from './Footer'
export default {
    components: { Footer },
}
</script>
