<template>
<div>
    <section class="pt-4 pt-md-11">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-5 col-lg-6 order-md-2">
                    <img src="/assets/img/illustrations/illustration-8.png"
                         class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0" alt="...">
                </div>
                <div class="col-12 col-md-7 col-lg-6 order-md-1">
                    <h1 class="display-4 text-center text-md-start">
                        <span class="text-primary text-nowrap">
                            Web3 Processing
                        </span><br>
                    </h1>
                    <p class="lead text-center text-md-start text-muted mb-2">
                        We will help you accept USDT tokens for your web application
                    </p>
                    <p class="lead text-center text-md-start text-muted mb-2">
                        We can notify your site about incoming payments,
                        generate blockchain addresses and withdraw tokens to your wallet
                    </p>
                    <p class="lead text-center text-md-start text-muted mb-2 mb-lg-8">

                    </p>
                    <div class="text-center text-md-start" v-show="!$root.context.id">
                        <router-link :to="{path: '/login'}" class="btn btn-primary shadow lift me-1">
                            Login <i class="fe fe-arrow-right d-none d-md-inline ms-3"></i>
                        </router-link>
                        <router-link :to="{path: '/registration'}" class="btn btn-primary-soft lift">
                            Registration
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="pt-8 pb-4 pt-md-10 pb-md-6">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10 text-center">
                    <h2>
                        Now we are ready to work with <span class="fw-bold text-primary-desat">
                            Ethereum and Tron
                        </span>
                    </h2>
                    <p>But soon we will add all popular blockchains like Bitcoin</p>
                </div>
            </div>
        </div>
    </section>
    <section class="py-1 py-md-2">
        <Plans />
    </section>
    <section class="py-2 py-md-6">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-8 text-center">
                    <h2 v-show="!$root.context.id">
                        <router-link class="fw-bold text-primary-desat" :to="{path: '/registration'}">
                            Register
                        </router-link> and start using our service!
                    </h2>
                    <p class="fs-lg text-muted mb-7 mb-md-9">
                        For making a transfer, 1% of the transfer amount is charged!
                    </p>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import Plans from './Plans'
export default {
    components: { Plans },
    data() {
        return {}
    },
    created() {
        if (location.search.indexOf("paymentId") != -1) {
            this.$router.replace({path: '/billing'})
        }
    }
}
</script>
