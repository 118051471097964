<template>
<section class="pt-8 pt-md-5 pb-8 pb-md-14">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="mb-6 text-danger" v-for="error in errors.__all__">{{ error }}</p>
                <Spinner v-if="!loaded" />
                <form class="mb-6" @submit.prevent="save" v-else>
                    <div class="form-group">
                        <label class="form-label" for="name">Project name:</label>
                        <input type="text" class="form-control" :class="{'is-invalid': errors.name}" id="name"
                               placeholder="Enter project name" v-model="form.name">
                        <small class="form-text text-danger" v-for="error in errors.name">{{ error }}</small>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="callbacks">Callback URL:</label>
                        <div v-for="item in form.callbacks">
                            <input type="text" class="form-control"
                                   :class="{'is-invalid': errors.callbacks}" id="callbacks"
                                   placeholder="Enter callback URL" :value="item.url"
                                   @input="event => item.url = event.target.value">
                        </div>
                        <small class="form-text text-danger" v-for="error in errors.callbacks">{{ error }}</small>
                    </div>
                    <button class="btn w-100 btn-primary" type="submit" :disabled="disabled">
                        Save
                    </button>
                </form>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { NotifyMixin } from '@/mixins'
import Spinner from '../Spinner'

export default {
    mixins: [NotifyMixin],
    components: { Spinner },
    data() {
        return {
            errors: {},
            form: {},
            loaded: false,
            disabled: false
        }
    },
    methods: {
        fetch() {
            this.loaded = false;
            let fields = ["id", "name", "callbacks"];
            this.$root.Project.get({id: this.$route.params.id}).then(resp => {
                for (let field of fields) {
                    this.$set(this.form, field, resp.body.item[field]);
                }
                if (this.form.callbacks.length < 1) {
                    this.$set(this.form, "callbacks" , [{url: ""}]);
                }
            }).finally(() => {
                this.loaded = true;
            });
        },
        save() {
            this.errors = {};
            this.disabled = true;
            this.$root.Project.update(this.form).then(resp => {
                this.errors = resp.body.errors || {};
                if (resp.body.state)
                    this.successNotify({ message: "Successful" });
            }).catch(resp => {
                this.errorNotify({ message: resp.body.detail });
            }).finally(() => {
                this.disabled = false;
            })
        }
    },
    created() {
        this.fetch()
    }
}
</script>
