<template>
<section class="pt-8 pt-md-5 pb-8 pb-md-14">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="mb-0 fw-bold">Dashboard</h1>
                <p class="mb-6 text-muted">Request statistics for your usage</p>
                <div class="row">
                    <div class="col-4">
                        <select class="form-select form-select-xs" name="project_id" v-model="project_id">
                            <option :value="0">By all projects</option>
                            <option
                                :value="item.id"
                                v-for="item in data.projects"
                                :key="item.id"
                                >{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="col-4">
                        <select class="form-select form-select-xs" name="daterange" v-model="daterange">
                            <option disabled>Date range:</option>
                            <option
                                :value="item.ident"
                                v-for="item in data.dateranges"
                                :key="item.ident"
                                >{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="col-4">
                        <select class="form-select form-select-xs" name="group_by" v-model="group_by">
                            <option disabled>Group by:</option>
                            <option
                                :value="item.ident"
                                v-for="item in data.groups"
                                :key="item.ident"
                                >{{ item.name }}</option>
                        </select>
                    </div>
                </div>
                <highcharts :options="chartOptions"></highcharts>
                <div class="table-responsive">
                    <vuetable
                        ref="vuetable"
                        :api-mode="false"
                        :data="tableData"
                        class="ui celled table unstackable table-scroll"
                        wrapper-class="vuetable-wrapper ui basic segment"
                        table-wrapper=".vuetable-wrapper"
                        :fields="fields"
                        @vuetable:pagination-data="onPaginationData"
                        >
                        <template slot="a1_cnt" slot-scope="props">{{ findSum(props.rowData, "a1") }}</template>
                        <template slot="a1_media" slot-scope="props">{{ findSum(props.rowData, "a1_media") }}</template>
                        <template slot="gql_cnt" slot-scope="props">{{ findSum(props.rowData, "gql") }}</template>
                        <template slot="gql_comments" slot-scope="props">{{ findSum(props.rowData, "gql_comments") }}</template>
                        <template slot="gql_comment_likers" slot-scope="props">{{ findSum(props.rowData, "gql_comment_likers") }}</template>
                        <template slot="gql_media_likers" slot-scope="props">{{ findSum(props.rowData, "gql_media_likers") }}</template>
                        <template slot="v1_cnt" slot-scope="props">{{ findSum(props.rowData, "v1") }}</template>
                        <template slot="v1_user" slot-scope="props">{{ findSum(props.rowData, "v1_user") }}</template>
                        <template slot="v1_location" slot-scope="props">{{ findSum(props.rowData, "v1_location") }}</template>
                        <template slot="v1_hashtag" slot-scope="props">{{ findSum(props.rowData, "v1_hashtag") }}</template>
                        <template slot="v1_highlight" slot-scope="props">{{ findSum(props.rowData, "v1_highlight") }}</template>
                        <!-- <template slot="v2_cnt" slot-scope="props">{{ findSum(props.rowData, "v2") }}</template> -->
                        <!-- <template slot="v2_user" slot-scope="props">{{ findSum(props.rowData, "v2_user") }}</template> -->
                    </vuetable>
                    <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable.vue";
import VuetablePagination from "./VuetablePagination";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import { toHour } from "utils/date";

export default {
    components: {
        Vuetable,
        VuetablePagination,
        highcharts: Chart
    },
    watch: {
        group_by(val) {
            this.setFilter("group_by", val);
        },
        daterange(val) {
            this.setFilter("daterange", val);
        },
        project_id(val) {
            this.setFilter("project_id", val);
        }
    },
    created() {
        this.$root.Data.get().then(resp => {
            this.data = resp.body;
        });
        this.filter();
    },
    data() {
        let filterData = window.getFilter("dash"),
            group_by = filterData.group_by || "g1_hours",
            daterange = filterData.daterange || "",
            project_id =
            filterData.project_id === undefined ? 0 : filterData.project_id;
        return {
            fields: [
                { name: "datetime_ts", title: "Date", callback: toHour(this.$root.lang) },
                { name: "__slot:v1_cnt", title: "/v1" },
                { name: "__slot:v1_user", title: "/v1/user" },
                { name: "v1_media", title: "/v1/media" },
                { name: "v1_story", title: "/v1/story" },
                { name: "__slot:v1_location", title: "/v1/location" },
                { name: "__slot:v1_hashtag", title: "/v1/hashtag" },
                { name: "v1_highlight", title: "/v1/highlight" },
                { name: "v1_share", title: "/v1/share" },
                // { name: "__slot:v2", title: "/v2" },
                // { name: "__slot:v2_user", title: "/v2/user" },
                { name: "v2_user_stories", title: "/v2/user/stories" },
                { name: "__slot:gql_cnt", title: "/gql" },
                { name: "__slot:gql_comments", title: "/gql/comments" },
                { name: "__slot:gql_comment_likers", title: "/gql/comment/likers" },
                { name: "__slot:gql_media_likers", title: "/gql/media/likers" },
                { name: "__slot:a1_cnt", title: "/a1" },
                { name: "a1_media", title: "/a1/user" },
                { name: "__slot:a1_media", title: "/a1/media" },
                { name: "a1_hashtag", title: "/a1/hashtag" },
                { name: "a1_location", title: "/a1/location" },
            ],
            tableData: {},
            data: {},
            group_by,
            daterange,
            project_id,
            filterData,
            chartOptions: {
                chart: {
                    type: "line",
                    zoomType: "x",
                    panning: true,
                    panKey: "shift",
                    scrollablePlotArea: {
                        minWidth: 600
                    }
                },
                title: {
                    text: "" // Statistics
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    // min: 0,
                    title: {
                        text: "Requests"
                    }
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        },
                        enableMouseTracking: false
                    }
                },
                series: []
            }
        };
    },
    methods: {
        findSum(obj, prefix) {
            let sum = 0;
            for (let property in obj) {
                if (property.startsWith(prefix)) {
                    sum += obj[property];
                }
            }
            return sum;
        },
        setFilter(key, val) {
            this.filterData[key] = val;
            window.setFilter("dash", this.filterData);
            this.filter();
        },
        filter(data) {
            let params = Object.assign(data || {}, this.filterData);
            this.$root.Stats.get(params).then(resp => {
                this.tableData = resp.body.table;
                let chartData = resp.body.chart;
                setTimeout(() => {
                    this.chartOptions.series = chartData.series;
                    this.chartOptions.xAxis.categories =
                        chartData.x_axis_categories;
                    // build Username -> yAxis map
                    let item,
                        maxFollowers = 0,
                        currentFollowers = 0,
                        seriesMap = {};
                    for (item of chartData.series) {
                        seriesMap[item.name] = item.data;
                        currentFollowers = Math.max.apply(null, item.data);
                        if (currentFollowers > maxFollowers)
                            maxFollowers = currentFollowers;
                    }
                });
            });
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.filter({ page });
        }
    }
};
</script>
